const Skeleton = {
  baseStyle: {
    borderRadius: 'lg',
  },
  defaultProps: {
    startColor: 'pink.200',
    endColor: 'purple.50',
  },
}

export default Skeleton
