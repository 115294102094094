const Alert = {
  baseStyle: {
    container: {
      borderRadius: 'lg',
    },
  },
  defaultProps: {
    colorScheme: 'purple',
  },
}

export default Alert
