const Input = {
  baseStyle: {
    field: {
      background: 'pink',
      color: 'gray.700',
    },
  },
  defaultProps: {
    focusBorderColor: 'brand.700',
  },
}

export default Input
